import React from "react";
import { PageHeader } from "../../../components";

export default function PRESIDENT() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_PRESIDENT.JPG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">ARC. ADEWALE OLUWAFUNMI BAMKOLE</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                    Arc Funmi Bamkole is the managing director of Holdman & Dot
                    Nig Ltd. He holds a Masters Degree in Architecture from the
                    Obafemi Awolowo University (formally University of ife, ile
                    ife). Before the inception of the company, he has risen to
                    the post of General Manager of Hortico Works Nig. Ltd, a
                    Landscape Construction Company in Lagos where he had
                    supervised project on behalf of the company for such clients
                    as Mobil, Chevron, Wapco, Nigeria German Chemical Company
                    Ltd. and Nicon Noga Hilton, Abuja among others. He remains a
                    Director in the Company till date.
                  </h4>
                  <p className="font-weight-3 00">
                    Holdman & Dot was incorporated in 1996. It is a
                    multidisciplinary outfit consisting of a team of dedicated
                    Architects, Engineers, Landscape Architects, Horticulturist
                    and the Technicians. Since inception, the company has been
                    involved in design and construction of Buildings and
                    Landscapes with a client profile consisting of private
                    respectable individuals, Corporate Organizations, State and
                    Federal Ministries with whom the company has always
                    maintained a mutually benefitting working relationship. His
                    other business interest includes being a director in Funland
                    Event Centre and Chairman Board of Directors, Ultimate Micro
                    Finance Bank, Lagos.
                  </p>
                  <p className="font-weight-3 00">
                    Architect Bamkole is married with children and enjoys sports
                    and social activities. He is a member of several Social
                    Clubs and Societies including Unilag Staff Club, 100 hours
                    Club (past chairman) and Lagos Country Club (past sport
                    secretary). He is currently the Chairman, Board of Trustees
                    of the Nigeria Squash Players Association of Nigeria.
                  </p>
                  <p className="font-weight-3 00">
                    In addition to sport and social associations, he is also
                    currently the Chairman, Environmental Committee Omole Phase
                    2 Housing Estate, President Government College Ikorodu Old
                    Student Association and Vice President, Fountain of Hope
                    Society of Archbishop Vining Memorial Church Cathedral
                    Ikeja.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
