const CARD_DATA = [
  {
    Items: [
      {
        id: 1,
        title: "President",
        name: "ARC. FUNMI BAMKOLE",
        imageUrl: "./assets/images/members/LCC_PRESIDENT.JPG",
        desc: " Arc Funmi Bamkole is the managing director of Holdman & Dot Nig Ltd. He holds a Masters Degree in Architecture from the Obafemi Awolowo University (formally University of ife, ile ife).",
        link: "/PRESIDENT",
      },
      {
        id: 2,
        title: "Vice President",
        name: "MR. IGHO OKOR",
        imageUrl: "./assets/images/members/LCC_VICE_PRESIDENT.JPG",
        link: "/VICEPRESIDENT",
        desc:
          "Business Development Leader with considerable experience in Brand Management, Banking (credit/risk management), Customer Care /After Sales Management.",
      },
      {
        id: 3,
        title: "Secretary General",
        name: "MR. ADEGBOYEGA ODUWOLE",
        imageUrl: "./assets/images/members/LCC_SECRETARY_GENERAL.JPG",
        desc:
          "A veteran administrator with over two decades experience as an HR Practitioner. He holds a Master’s degree in Psychology and B.Sc in Economics. A thorough bred manager of repute who has held several other key positions in social circles. He is an ardent sport lover who plays for and represents the Club at tourneys and competitions. He has many medals to show for this. He is a listener and a believer in the good of humanity.",
        link: "/SECRETARYGENERAL",
      },
      {
        id: 4,
        title: "Hon. Treasurer",
        name: "MR. DELE OSHIGA",
        imageUrl: "./assets/images/members/LCC_TREASURER.JPG",
        desc:
          "Dele Oshiga FCA. He is Fellow of the Institute of Chartered Accountant of Nigeria, holds an MBA from Cranfield University United Kingdom. He is a Certified Fraud Examiner of the Association of Certified Fraud Examiner (ACFE ) USA",
        link: "/HONTREASURER",
      },
      {
        id: 5,
        title: "Membership Secretary",
        name: "Ola Azeez, PhD, MBA, FCIPM, FCILG, FICMi, HRPL, FITD, HRPL.",
        imageUrl: "./assets/images/members/LCC_MEMBESHIP_SECRETARY.jpeg",
        desc:
          `Olasunkade Azeez has garnered high value net worth experiences as HR Leader, Business Process Improvement expert, Certified Coach/Mentor, Learning, Training and Development Leader, Senior Consultant, Member, Board of Directors, Board of Fellows, Governing Councils and Board of Trustees (BOT) across manufacturing, oil and gas, consulting, construction, real estate, aviation sectors, and Professional Institutes of the Nigerian economy in the last 3 decades. He started his working career with the Coca Cola Hellenic Bottling Company (CCHBC), immediately after graduation in 1991. He served the Company meritoriously in various capacities that prepared him for subsequent value-creating career path. \n \n Ola Azeez is an alumnus of Obafemi Awolowo University, Ile-Ife, University of Lagos, and Lagos State University, Ojo and he bagged Bachelor’s Degrees in Social Studies Education, Executive Diploma HRM, Postgraduate Diploma (PR), Nigerian Institute of Journalism, and MBA Marketing Management respectively. He holds Professional Doctor of Administration of Chartered Institute of Business Managers and Administrators, Delaware, USA. He is also an alumnus of many advanced management development programmes in Nigeria and abroad. He is an internationally certified Trainer in HR, Talent Management, Coaching, Mentoring, Performance Management Systems, Negotiation, Leadership, and Business Process Management System. Trained in Nigeria, Austria, Greece, Czech Republic, Ireland, Dublin, and Romania etc. He is the Managing Partner/CEO of REMOLAZ Management Consults, a division of Remolaz International (Nig) Ltd.\n \n He is currently the Group Head, Human Resources at Resort International Limited and subsidiaries. The Holding Company comprises of fortune Companies in the Aviation, Construction and Properties Development industries. He was a Senior Consultant and Team 
          Lead on HR Strategy and Change Management projects with Impact Business Consulting Services, a Business Strategic Partner with IBM, USA. He joined CITA Petroleum Group Executive Team – (an IATA Strategic Partner and multiple awards winning enterprise) as General Manager, Human Capital with over 2 decades of value adding business partnering experience in HRM, Sales Operations, and General Administration, across various sectors. In addition to Human Capital portfolio, he managed the Logistics subsidiary – JIL Transport as its Chief Operating Officer in addition to an oversight responsibility for the Commercial and Business Development functions of the Group and subsequently became the COO, CITA Organic
          Ltd, with key responsibility for non-aviation fuelling business of the group. He is a change
          management and strategic turnaround expert.
          \n\n
          As a thoroughbred professional, he is a Fellow and a past Vice President of the Nigerian
Institute of Training and Development, (NITAD), a Fellow, and a one-time, Governing Council
Member of Chartered Institute of Nigeria, (CIPM) and also a Fellow/Member of Board of
Trustees (BOT) of Change Management Institute International (ICMi). He is a Fellow and Lagos
State Coordinator of Nigeria Chapter of Chartered Institute of Leadership and Governance, USA.
He is also a governing Council member of Grand Plus College of Education, Ilorin Kwara State,
Nigeria and OML Accra, Ghana Republic.
\n\n
He is a columnist, writer and an author of two books and co-authored Learning Management
Systems and Facilitation (A cookbook for 21st century trainers) published in April 2022.
\n
Ola belongs to many other recreational, social and philanthropic associations. He is a member
of Association of International Lions Clubs, a onetime President, Ilupeju Lions Club and District
Officer under 404B2, Nigeria. A member of Ibadan Recreation Club, Omo Ajorosun Club, Ibadan
and member, Board of Trustees of Ibadan Borokinni Worldwide Forum (IBWF) among others.
He is the immediate past Vice Chairman of Badminton Section. He has served the Club in
various Committees of Council of the Club including Membership Screening, Staff and
Secretariat Staff Matters, 70th Planning Committee among others. \n\n
Ola is happily married with children.
          `,
        link: "/MEMBERSHIPSECRETARY",
      },
      {
        id: 6,
        title: "Hon, Social Secretary",
        name: "MR. OLUSEGUN KUTI",
        imageUrl: "./assets/images/members/LCC_SOCIAL_SECRETARY.JPG",
        desc:
          "Pharmacist Director of sales@ Medheights pharmacy ltd MD Flourville pharmacy ltd",
        link: "/HONSOCIALSECRETARY",
      },
      {
        id: 7,
        title: "SPORTS SECRETARY",
        name: "MR. SEGUN ALUKO",
        imageUrl: "./assets/images/members/LCC_CHAIRMAN_SQUASH.JPG",
        desc: "He is a Marketing communications consultant with a bias for strategic brand management and consumer engagement. As an ex national basketball player and currently one of the outstanding tennis players in Lagos country club, Aluko has strong passion for sports development. He is currently the president of the Tennis Players Parents Association in Nigeria. He is co-founder and chairman of Maravilla International School. A school that gives academic and sports scholarship to children. ",
        link: "/SPORTSSECRETARY",
      },
      {
        id: 8,
        title: "Hon. Council Member ",
        name: "Arc. Ashiru Tokunbo.",
        imageUrl: "./assets/images/members/LCC_COUNCI_MEMBER.jpeg",
        desc:
          `Arc Adetokunbo Ashiru. is the MD/CEO ASHTKB VENTURES LTD He holds a B.Tech. Architecture from the Rivers State University of Science and Technology, Port Harcourt, Rivers State and Master’s Degree in Architecture at the same university. He also holds Diploma in Computing at AutoCad Centre Rivers State as well as America Project Management British College UK (BSPM UK), Executive Masters Project Management-British College UK (BSPM College) and Advance Diploma Project Management British College UK (BSPM UK).  Before the inception of his company, he has served as Project Manager/Architect with UBAPROPERTIES now Afriland Properties Plc and also rose through the ranks at United Bank of Africa Plc to the post of Project Manager/Architect UBA Properties. \n\n
          Ashiru also enjoys sports and social activities, he is a member Proud Member of Lagos Country Club and have served in various capacity particularly at Aerobics Taekwondo section and other sub-committees of the club. He has a 1st Dan Black Belt on KUKKIWON World Taekwondo Tournament in Korea.      
          Architect Ashiru is married to Mrs Ekene Ashiru and are blessed with children.`,
          link: "/HONCOUNCILMEMBER",
      },
      {
        id: 9,
        title: "LEGAL ADVISER",
        name: "MR. AYO OLANREWAJU",
        imageUrl: "./assets/images/members/AYO_OLANREWAJU.jpeg",
        desc:
          "He is a legal practioner and law publisher. He was formerly Chairman Tennis Section and Chairman, Disciplinary Committee of the Lagos Country Club",
        link: "/LEGALADVISER",
      },
      {
        id: 10,
        title: "LCC GENERAL MANAGER",
        name: "MR. AKOGU JOHN",
        imageUrl: "./assets/images/members/LCC_GENERAL_MANAGER.JPG",
        desc: "Born in 1968 to the family of Mr & Mrs Isreal Shaibu Akogu, He obtained HND (Microbiology) from Yaba College of Technology. He went further to obtain PGD in Public Relations and MBA from Olabisi Onobanjo University Ago-Iwoye.Mr. John Akogu started his career in Hospitality Industry with Sheraton Lagos Hotel and Towers in 1989 where he grew through ranks to become Banquet Coordinator. He became a Pioneer Food and Beverage Manager of Rock View Hotel Apapa, Lagos and later became General Manager, Eko Tourist Beach Resort. As a seasoned hospitality expert, He has worked and consulted for many Hotels and Guest Houses and he is currently the General Manager of Lagos Country Club, the foremost family Club in Nigeria.He belongs to many associations, and a Fellow Institute of Change Management (FiCM). He is happily married with children.",
        link: "/GENERALMANAGER",
      },
      {
        id: 11,
        title: "CHAIRMAN, SNOOKER/BILLIARDS",
        name: "MR. KAYODE OLUWOLE",
        imageUrl: "./assets/images/members/LCC3.jpeg",
        desc: "Mr. Oluwole Olukayode, is a renowned Data and communications expert with an established multinational in the health management sector of the economy. He was Past Vice Chairman,  Past Captain and Past Committee Member Snooker/Billiards Section.He believes asking the right questions is necessary to move to the next level.",
        link: "/CHAIRMANSNOOKER",
      },
      {
        id: 12,
        title: "CHAIRMAN, SWIMMING SECTION",
        name: "MR. AYOKO OLUSOJI HENRY",
        imageUrl: "./assets/images/members/LCC2.JPG",
        desc: "My name is Ayoko Olusoji Henry. I am currently Chairman, Swimming Section. I am an astute entrepreneur with bias for export trade and merchandise. I have served in various capacities, such as E.O., Secretary and Vice Chairman respectively. Always ready to give service to humanity.",
        link: "/CHAIRMANSWIMMING",
      },
      {
        id: 13,
        title: "CHAIRMAN, TENNIS SECTION",
        name: "BABATUNDE A.M. FASHANU,  SAN",
        imageUrl: "./assets/images/members/Chairman_Tennis.jpeg",
        desc:
          "",
        link: "/CHAIRMANTENNIS",
      }, 
      {
        id: 14,
        title: "LCC CHAIRMAN DARTS",
        name: "PRINCE OWOLABI MUMUNI",
        imageUrl: "./assets/images/members/LCC_CHAIREMAN_DARTS.JPG",
        desc: "Owolabi Mumuni - Chairman Darts Section. Retired  Banker. Past Captain & Vice/Chairman of the Section Darts.",
        link: "/CHAIREMANDARTT",
      },
      {
        id: 15,
        title: "CHAIRMAN, BADMINTON SECTION",
        name: "MR. MARCEL EGUABOR",
        imageUrl: "./assets/images/members/CHAIRMAN_BADMINTON_SECTION.jpeg",
        desc:
          "Financial Services and Corporate Communication professional with a passion for Badminton, Walking,Swimming and humanitarian Services.",
        link: "/CHAIRMANBADMINTON",
      },
      {
        id: 16,
        title: "LCC CHAIRMAN TABLE TENNIS",
        name: "ALH. TUNJI LAWAL",
        imageUrl: "./assets/images/members/LCC_CHAIRMAN_TABLE_TENNIS.jpeg",
        desc:
          "Alhaji Olatunji Lawal, Chairman, Table Tennis Section. An established philanthropist, sport lover and enthusiast. MD/CEO, TOON CONSOLIDATED COMPANY LTD, (A major Exporter of Hibiscus Flower).President, Association of Hibiscus Flower Exporters of Nigeria (AHFEN), Chairman, Lagos State Table Tennis Association, Member: Nigeria Agro Export Committee (NAEC).",
        link: "/CHAIRMANTABLE",
      },
      {
        id: 17,
        title: "LCC Chairman Squash",
        name: "SIR 'SEMOORE BADEJO, FIIM",
        imageUrl: "./assets/images/members/chairman_squash.jpeg",
        desc:
          "Chairman, Squash Section, Lagos Country Club. A Member and dedicated Recreational & Social User of the Club. Squash is Life!",
        link: "/CHAIRMANSQUASH",
      },
      {
        id: 18,
        title: "LCC CHAIRMAN SCRABBLE",
        name: "Tunde Thani.",
        imageUrl: "./assets/images/members/LCC_CHAIRMAN_SCRABBLE.jpeg",
        desc:
          `Thunde Thani was born to the Thani-Oladunjoye family of Epe, Lagos State, on 10th August,
          1960.
          Thani had his Primary Education at St Michael Anglican Primary School (CMS) Epe, his
          Secondary Education at Ansar-ud-deen High School, Surulere, Lagos. He attended the
          prestigious University of Lagos where he obtained his BSC (Hons) Mass Communication with
          2
          nd Class Upper. He also holds an MSC in mass communication from the same university.
          An astute communicator, marketing and advertising expert. He is also a vivacious entrepreneur.
          He is a fellow of Advertising Practitioners Council (APCON), severally serve on its board and
          served as vice Chairman of the engine room of the body, the Advertising Standard Panel (ASP).
          with over 3 decades in marketing communications practice, Ivorian Thani held many Executive
          positions in the Association of Advertising Agencies of Nigeria (AAAN) and has handled multisectoral businesses/accounts including FMCG, Banking and Finance, Oil and Gas,
          Government/Politics, Pharmaceuticals and multinationals. He sits as the managing Director/CEO
          of the multi-award winning explicit group, a conglomerate of marketing communications
          companies.
          Thani a Philanthropist, recently facilitated a non-profit care Centre – Thani Oladunjoye Old
          People Care Centre, for senior citizens in his community, Epe, Lagos State. He has won many
          awards, both for his philanthropic gesture, social and political acumen including the prestigious
          University of Lagos, Alumni Association Golden Jubilee Distinguishes Alumni Award and
          Unilag Mass Communication Alumni Association UMCAA’s outstanding Alumni Entrepreneur
          in Mass Communication Award – both award he cherishes.
          It is not all work and no play. Thani is a Table Tennis buff and an ardent Scrabble player. He is
          currently the Chairman of Lagos Country Club Scrabble Section. He is also a prominent member
          of Great Summit Club.
          Tunde Thani is happily Married to Christiana Omobolanle Thani and blessed with children.`,
        link: "/CHAIRMANSCRABBLE",
      },
      {
        id: 19,
        name: "MR. ADETAYO ADEGBOYE",
        title: "LCC AEROBICS TAKEWONDO",
        imageUrl: "./assets/images/members/LCC_AEROBICS_TAKEWONDO.JPG",
        desc:
          "Chairman Aerobics/Takewondo  Astute Insurance professional with passion for human capital development,professionalism and teamwork .Loves reading physical exercise and social engagement.",
        link: "/AEROBICSTAKEWONDO",
      },
      // {
      //   id: 20,
      //   title: "CHAIRMAN, OTHER GAMES",
      //   name: "PSTR.  OYEYEMI TAIWO",
      //   imageUrl: "./assets/images/members/Chairman_other_games.jpeg",
      //   desc: "...",
      //   link: "",
      // },
      {
        id: 20,
        title: "CHAIRMAN, OTHER GAMES",
        name: "PRINCESS.  TOLA ADELE OLADOYIN",
        imageUrl: "./assets/images/members/Chairman_other_games.jpeg",
        desc: "She is an active member of our prestigious Club, she was the Past Sport Secretary of the Club, a past Captain of Darts Section and past Vice Chairman of Other Games section. She was a formal National Table Tannis player and Formal National Female Dart champion. A former South West Zonal Coordinator of Dart Federation of Nigeria (DFN).",
        link: "/OTHERGAME",
      },
    ],
  },
];

export default CARD_DATA;
