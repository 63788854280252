import React from "react";
import { PageHeader } from "../../../components";

export default function GENERALMANAGER() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_GENERAL_MANAGER.JPG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">MR. AKOGU JOHN</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                    Born in 1968 to the family of Mr & Mrs Isreal Shaibu Akogu,
                    He obtained HND (Microbiology) from Yaba College of
                    Technology. He went further to obtain PGD in Public
                    Relations and MBA from Olabisi Onobanjo University
                    Ago-Iwoye. Mr. John Akogu started his career in Hospitality
                    Industry with Sheraton Lagos Hotel and Towers in 1989 where
                    he grew through ranks to become Banquet Coordinator.
                  </h4>
                  <h4 className="font-weight-3 00">
                    He became a Pioneer Food and Beverage Manager of Rock View
                    Hotel Apapa, Lagos and later became General Manager, Eko
                    Tourist Beach Resort. As a seasoned hospitality expert, He
                    has worked and consulted for many Hotels and Guest Houses
                    and he is currently the General Manager of Lagos Country
                    Club, the foremost family Club in Nigeria. He belongs to
                    many associations, and a Fellow Institute of Change
                    Management (FiCM). He is happily married with children.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
