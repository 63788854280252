import React from "react";

const GrandPatron = () => (
  <>
    <div className="col-md-12">
      <div className="dlab-box m-b30 team-1">
        <div
          className="dlab-media"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assets/images/patrons/Buhari.jpg"
            alt="Buhari"
            style={{ height: "270px", objectFit: "contain" }}
          />
        </div>
        <div className="p-a10">
          <h4 className="dlab-title text-uppercase">President Muhammadu Buhari</h4>
          <p className="dlab-member-position">
            The President, Federal Republic of Nigeria
            His Excellency, President Muhammadu Buhari, GCFR, GCON.
          </p>
        </div>
      </div>
    </div>
  </>
);

export default GrandPatron;
