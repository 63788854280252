import React from "react";
import { PageHeader } from "../../../components";

export default function SECRETARYGENERAL() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_SECRETARY_GENERAL.JPG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">MR. ADEGBOYEGA ODUWOLE</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                    A veteran administrator with over two
                    decades experience as an HR Practitioner. He holds a
                    Master’s degree in Psychology and B.Sc in Economics. A
                    thorough bred manager of repute who has held several other
                    key positions in social circles. He is an ardent sport lover
                    who plays for and represents the Club at tourneys and
                    competitions. He has many medals to show for this. He is a
                    listener and a believer in the good of humanity.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
