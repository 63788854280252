import React from "react";
import { PageHeader } from "../../../components";

export default function SPORTSSECRETARY() {
  return (
    <div className="page-content">
      <PageHeader title="Management Details" page="management details" />
      {/* <!-- Content --> */}
      <div className="page-content">
        {/* <!-- Breadcrumb row END --> */}
        {/* <!-- contact --> */}
        <div className="content">
          {/* <!-- About Company --> */}
          <div className="section-fullbg-gray content-inner bg-gray ">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 hidden-sm">
                  <img
                    src="./assets/images/members/LCC_CHAIRMAN_SQUASH.JPG"
                    alt=""
                    className="m-b30"
                    width="440"
                  />
                </div>
                <div className="col-md-7 col-sm-12">
                  <h3 className="h3">MR. SEGUN ALUKO</h3>
                  <div className="dlab-separator bg-primary"></div>
                  <h4 className="font-weight-3 00">
                    He is a Marketing communications consultant with a bias for
                    strategic brand management and consumer engagement. As an ex
                    national basketball player and currently one of the
                    outstanding tennis players in Lagos country club, Aluko has
                    strong passion for sports development. He is currently the
                    president of the Tennis Players Parents Association in
                    Nigeria. He is co-founder and chairman of Maravilla
                    International School. A school that gives academic and
                    sports scholarship to children.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- contact area  END --> */}
      </div>
    </div>
  );
}
